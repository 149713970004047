<template>
    <Dialog v-model:visible="displayAgregarRegistro" :breakpoints="{ '960px': '95vw' }"
        style="width:644px;height:558px;background:#FFFFFF 0% 0% no-repeat padding-box;border-radius:30px;"
        :modal="true" class="modal-publications">
        <form action="">
            <div>
                <div class="heaeder-img-modal">
                    <img class="w-6" src="@/assets/images/icons/modal-createpub-icon.png" />
                </div>
                <span class="text-base font-bold title header-modal">Nueva Publicación</span>
            </div>
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-4 ">
                <div class="lg:col-span-4">
                    <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                        <div class="md:col-span-6">
                            <label class="label-modal" for="titulo">Titulo:</label>
                            <InputText v-model="modelo.titulo" :class="`titulo w-full`" />
                            <small class="text-red-600" v-if="errors.titulo">{{ errors.titulo }}</small>
                        </div>
                        <div class="md:col-span-3">
                            <label class="label-modal" for="fechaPublicacion">Fecha de publicación:</label>
                            <input :class="`h-10 border mt-1 rounded px-2 w-full border-input-date`" type="date"
                                v-model="modelo.fechaPublicacion" />
                            <small class="text-red-600" v-if="errors.fechaPublicacion">
                                {{ errors.fechaPublicacion }}
                            </small>
                        </div>
                        <div class="md:col-span-3" style="display: grid;">
                            <label class="label-modal" for="tipo">Tipo:</label>
                            <div class="flex">
                                <InputText v-show="addTipo" v-model="modelo.tipoPublicacionId" :class="`titulo w-full`"
                                    placeholder="Nombre nuevo tipo..." />

                                <Dropdown v-show="!addTipo" v-model="modelo.tipoPublicacionId" :options="filterCategory"
                                    optionLabel="nombre" option-value="id" placeholder="Seleccione el tipo..."
                                    :class="`p-inputtext-sm select-modal-create tipo-select`" />

                                <i v-show="!addTipo" @click="onChangeTipo()"
                                    class="pi pi-plus-circle text-primary my-auto text-2xl ml-3 cursor-pointer" />
                            </div>
                            <small class="text-red-600" v-if="errors.tipoPublicacionId">
                                {{ errors.tipoPublicacionId }}
                            </small>
                        </div>
                        <label class="label-modal" for="archivo">Archivo:</label>
                        <div class="md:col-span-6 uploadfile">
                            <div class="p-inputgroup label-modal">
                                <InputText disabled v-model="fileName" style="border: none;" />
                                <FileUpload mode="basic" name="demo[]"
                                @change="handleFilesUpload()"
                                invalidFileSizeMessage="Por favor selecciona un documento tipo pdf o mp4"
                                class="p-button-warning img-file styleUpload-label" ref="inputFile" />
                                <Button style="margin-right:5px"
                                class="p-button-warning btn-modal-icons trash-file styleUpload-label"
                                @click="resetFile()" />
                            </div>
                            <small class="text-red-600 pl-2" v-if="fileErrors">
                              {{ fileErrors }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <template #footer class="border-modal-footer">
            <div style="display: flex;justify-content: center;">
                <Button label="Cancelar" class="p-button-success btn-footer-modal-cancel"
                    @click="displayAgregarRegistro=false" />
                <Button label="Guardar" class="p-button-success btn-footer-modal-save" autofocus @click="onSubmit()" />
            </div>
        </template>
    </Dialog>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'
import { useToast } from 'primevue/usetoast'
import Dropdown from 'primevue/dropdown'
import createPublicationesService from '../services/createPublicationesService'
import tipoPublicacionesService from '../services/getTipoPublicacionesService'
// import { messageConfirmSave } from '../../../../../libs/https/mensajes'

export default {
    name: 'Admin-publications-modal',
    components: {
        Dropdown
    },

    setup (props, { emit }) {
        const toast = useToast()
        const displayAgregarRegistro = ref(false)
        const filterCategory = ref([])
        const inputFile = ref()
        const addTipo = ref(false)
        const fileName = ref('')
        const fileErrors = ref('')

        /* Esquema de validacion con sus reglas */
        const validationSchema = object({
            fechaPublicacion: string().nullable().required().label('Fecha de Publicación'),
            titulo: string().nullable().required().label('Titulo'),
            tipoPublicacionId: string().nullable().required().label('Tipo')
        })

        /* Variables y propiedades del formulario para validar */
        const { errors, handleSubmit, values: modelo, handleReset } = useForm({
            validationSchema
        })

        /* Inicializando campo en el formulario */
        useField('fechaPublicacion', null, { initialValue: null })
        useField('titulo', null, { initialValue: null })
        useField('tipoPublicacionId', null, { initialValue: null })

        const openModal = () => {
            handleReset()
            resetFile()
            addTipo.value = false
            getTipoPublicaciones().then(() => {
                displayAgregarRegistro.value = true
            })
        }

        const onSubmit = handleSubmit((values) => {
            // messageConfirmSave('no es posible deshacer esta accion').then(({ isConfirmed }) => {
            //     if (!isConfirmed) return false
                if (!handleFilesUpload()) return false
                displayAgregarRegistro.value = false
                const formData = new FormData()
                formData.append('titulo', modelo.titulo)
                formData.append('fechaPublicacion', modelo.fechaPublicacion)
                formData.append('tipoPublicacionId', modelo.tipoPublicacionId)
                formData.append('file', inputFile.value.files[0])
                createPublicationesService(formData).then(() => {
                    emit('refresh')
                    toast.add({
                        severity: 'success',
                        summary: 'Exito',
                        detail: 'Documento cargado con exito',
                        life: 3000
                    })
                })
            // })
        })

        const getTipoPublicaciones = async () => {
            return await tipoPublicacionesService().then(({ data }) => {
                filterCategory.value = data
            })
        }

      const resetFile = () => {
        inputFile.value = null
        fileErrors.value = ''
        fileName.value = ''
      }

        const handleFilesUpload = () => {
          fileErrors.value = ''
          const file = inputFile.value.files[0]
          if (!file) return true
          fileName.value = file.name
          if (inputFile.value.files.length) {
            if (inputFile.value.files[0].size > 50000000) {
              fileErrors.value = 'El archivo adjunto a superado maximo tamaño requerido (50 MB)'
            } else if (!['application/pdf', 'video/mp4'].includes(inputFile.value.files[0].type)) {
              fileErrors.value = 'Solo se acepta los formatos: .pdf y .mp4'
            }
          }
          return !fileErrors.value
        }

        const onChangeTipo = () => {
            modelo.tipoPublicacionId = ''
            addTipo.value = true
        }

        onMounted(() => {
            getTipoPublicaciones()
        })

        return {
            displayAgregarRegistro,
            modelo,
            errors,
            filterCategory,
            inputFile,
            addTipo,
          fileName,
          fileErrors,
            onChangeTipo,
            openModal,
            onSubmit,
            handleFilesUpload,
          resetFile
        }
    }
}
</script>
<style scoped>
.titulo:focus {
    border-color: none !important;
}
.tipo-select {
    width: 100%;
    display: flex;
    align-self: flex-end;
    height: -webkit-fill-available;
    width: 100%
}

.p-dialog-footer.border-modal-footer {
    border-top: 5px solid #1E40AF !important;
    border-bottom: 5px solid #1E40AF !important;
}
</style>
