<template>
  <Card>
    <template #content>
      <div class="grid gap-4 gap-y-2 text-sm grid-cols-12 lg:grid-cols-12">
        <div class="col-span-6" style="display: flex;align-items: center;">
          <i class="pi pi-align-justify text-sm icon-title"></i>
          <span class="text-base font-bold title">Administrar Publicaciones</span>
        </div>
        <div class="col-span-6 flex justify-end">
          <Button type="button" label="Nueva publicación"
            class="p-button no-transparent text-sm font-medium btn-nueva-publicacion"
            @click="$refs.createModal.openModal()" />
        </div>
      </div>
      <hr class="solid separator-header">
      <div>
        <Toast />
        <DataTable @rowEditInit="onRowEditInit" :value="publications" @rowReorder="onRowReorder"
          responsiveLayout="scroll" editMode="row" class="editable-cells-table" v-model:filters="filters1"
          v-model:editingRows="editingRows" filterDisplay="menu" dataKey="id" v-model:selection="selectedProduct"
          selectionMode="single" @sort="orderFiled">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column field="estado" header="Estado" headerStyle="width: 3rem">
            <template #body="{ data }">
              <InputSwitch @change="onChangeEstado(data.id)" v-model="data.estado" />
            </template>
            <template #editor="{ data }">
              <InputSwitch @change="onChangeEstado(data.id)" v-model="data.estado" />
            </template>
          </Column>
          <Column field="titulo" header="TITULO" style="min-width: 70%; font-size:11px;font-weight: bold;">
            <template #editor="{ data }">
              <InputText v-model="modelo.titulo" class="select-publi-table label-modal" :data-titulo="data.titulo" />
              <small class="text-red-600" v-if="errors.titulo">{{ errors.titulo }}</small>
            </template>
          </Column>
          <Column field="tipo.nombre" header="TIPO" style="min-width: 70%; font-size:11px;" dataType="text">
            <template #editor="">
              <div class="flex">
                <InputText v-show="addTipo" v-model="modelo.tipoPublicacionId" :class="`titulo w-full`"
                  placeholder="Nombre nuevo tipo..." />

                <Dropdown v-show="!addTipo" v-model="modelo.tipoPublicacionId" :options="filterCategory"
                  optionLabel="nombre" option-value="id" placeholder="Seleccione el tipo..."
                  :class="`p-inputtext-sm select-modal-create tipo-select`" />

                <i v-show="!addTipo" @click="onChangeTipo()"
                  class="pi pi-plus-circle text-primary my-auto text-2xl ml-3 cursor-pointer" />
              </div>
              <small class="text-red-600" v-if="errors.tipoPublicacionId">
                {{ errors.tipoPublicacionId }}
              </small>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
            </template>
          </Column>
          <Column field="fechaPublicacion" header="FECHA DE PUBLICACIÓN" sortable
            style="min-width: 70%; font-size:11px;">
            <template #editor="">
              <input class="select-publi-table label-modal" v-model="modelo.fechaPublicacion" type="date">
              <small class="text-red-600" v-if="errors.fechaPublicacion">
                {{ errors.fechaPublicacion }}
              </small>
            </template>
            <template #body="{ data }">
              {{ formatDate(data.fechaPublicacion, "DD-MM-YYYY") }}
            </template>
          </Column>
          <Column field="userCreated.persona.full_name" header="CREADO POR" style="min-width: 70%; font-size:11px;">
            <template #body="{ data }">
              <span v-if="data.userCreated">
                {{data.userCreated.persona ? data.userCreated.persona.full_name : ''}}
              </span>
            </template>
          </Column>
          <Column field="path" header="ARCHIVO" class="img-adjunto-modal p-inputtext-sm"
            style="min-width: 70%; font-size:11px;vertical-align: inherit;">
            <template #editor="">
              <div class="md:col-span-6 table-uploadfile pl-2" style="background: #ffffff;">
                <div class="p-inputgroup label-modal"
                  style="background: #ffffff;display: flex;justify-content: space-between;align-items: center;">
                  <p style="font-size:11px;">{{ fileName }}</p>
                  <FileUpload mode="basic" name="demo[]" @change="handleFilesUpload()"
                    chooseLabel=" " invalidFileSizeMessage="Por favor selecciona un documento tipo pdf o mp4"
                    class="p-button-warning img-file styleUpload-label" ref="inputFile" />
                </div>
                <small class="text-red-600" v-if="fileErrors">
                  {{ fileErrors }}
                </small>
              </div>
            </template>
          </Column>
          <Column field="" header="ACCIONES">
            <template #body="{ data }">
              <Button class="p-button-xs view-icon" @click="showPublication(data.path)" />
              <Button class="p-button-xs btn-edit" @click="editPublication(data)" />
              <Button class="p-button-xs btn-delete" @click="onConfirmDelete(data.id)" />
            </template>
            <template #editor="{ data }">
              <Button class="p-button-xs btn-edit" @click="editPublication(data)" />
              <Button class="p-button-xs btn-delete" @click="onConfirmDelete(data.id)" />
              <Button class="p-button-xs btn-save" @click="onSubmit()" />
            </template>
          </Column>
        </DataTable>
        <div class="flex justify-center">
          <Paginator @page="onFilter" v-model:first="filter.offset" v-model:rows="filter.limit"
            :totalRecords="filter.total" :rowsPerPageOptions="[10,20,30]" />
        </div>
      </div>

      <Dialog v-model:visible="displayDeleteRegistro" :breakpoints="{'960px': '95vw'}"
        style="width:540px;height:288px;background:#FFFFFF 0% 0% no-repeat padding-box;border-radius:30px;"
        :modal="true">
        <form action="">
          <div>
            <div class="heaeder-img-modal">
              <img class="w-6" src="@/assets/images/icons/delete-modal.png" />
            </div>
            <span class="text-base font-bold title header-modal">¿Desea Eliminar?</span>
          </div>

        </form>
        <template #footer>
          <div style="display: flex;justify-content: center;">
            <Button label="No" class="p-button-success btn-footer-modal-cancel" @click="displayDeleteRegistro=false" />
            <Button label="Eliminar" class="p-button-success btn-footer-modal-delete" @click="deletePublication()"
              autofocus />
          </div>
        </template>
      </Dialog>

      <create-modal ref="createModal" @refresh="onLoad()" />

    </template>
  </Card>
</template>

<script>

import { ref, onMounted, watch } from 'vue'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'
import { useToast } from 'primevue/usetoast'
import Dropdown from 'primevue/dropdown'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import publicacionService from '../services/getPublicacionesService'
import { useRoute, useRouter } from 'vue-router'
import tipoPublicacionesService from '../services/getTipoPublicacionesService'
import orderPublicationService from '../services/updateOrderPublicationesService'
import updatePublicationesService from '../services/updatePublicationesService'
import deletePublicationesService from '../services/deletePublicationesService'
import statePublicationesService from '../services/statePublicationesService'
import createModal from '../components/formModal.vue'
import dayjs from 'dayjs'
import readFileService from '../services/readFileService'

export default {
    name: 'Admin-publications',
    components: {
        Dropdown,
        createModal
    },

    setup (props, { emits }) {
        const route = useRoute()
        const router = useRouter()
        const editingRows = ref([])
        const displayDeleteRegistro = ref(false)
        const toast = useToast()
        const isDisabledSupplier = ref(true)
        const idTemporal = ref()
        const isEdit = ref(false)
        const selectedProduct = ref()
        const publications = ref([])
        const inputFile = ref()
        const filterCategory = ref([])
        const addTipo = ref(false)
        const fileName = ref('')
        const fileErrors = ref('')
        const filter = ref({
            total: 0,
            offset: 0,
            limit: 10,
            order: 'orden,1'
        })

        const filters1 = ref({
            tipo: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            }
        })

        /* Esquema de validacion con sus reglas */
        const validationSchema = object({
            fechaPublicacion: string().nullable().required().label('Fecha de Publicación'),
            titulo: string().nullable().required().label('Titulo'),
            tipoPublicacionId: string().nullable().required().label('Tipo')
        })

        /* Variables y propiedades del formulario para validar */
        const { errors, handleSubmit, values: modelo, handleReset } = useForm({
            validationSchema
        })

        /* Inicializando campo en el formulario */
        useField('fechaPublicacion', null, { initialValue: null })
        useField('titulo', null, { initialValue: null })
        useField('tipoPublicacionId', null, { initialValue: null })

        const getTipoPublicaciones = async () => {
            return await tipoPublicacionesService().then(({ data }) => {
                filterCategory.value = data
            })
        }
        const editPublication = (data) => {
            handleReset()
            addTipo.value = false
            if (isEdit.value) {
              isEdit.value = false
              editingRows.value = []
              resetFile()
            } else {
                getTipoPublicaciones().then(() => {
                    isEdit.value = true
                    editingRows.value = [data]

                    modelo.id = data.id
                    modelo.titulo = data.titulo
                    modelo.fechaPublicacion = data.fechaPublicacion
                    modelo.tipoPublicacionId = data.tipoPublicacionId
                    fileName.value = data.path
                })
            }
        }
      const resetFile = () => {
        inputFile.value = null
        fileErrors.value = ''
        fileName.value = ''
      }
      const handleFilesUpload = () => {
        fileErrors.value = ''
        const file = inputFile.value.files[0]
        if (!file) return true
        fileName.value = file.name
        if (inputFile.value.files.length) {
          if (inputFile.value.files[0].size > 50000000) {
            fileErrors.value = 'El archivo adjunto a superado maximo tamaño requerido (50 MB)'
          } else if (!['application/pdf', 'video/mp4'].includes(inputFile.value.files[0].type)) {
            fileErrors.value = 'Solo se acepta los formatos: .pdf y .mp4'
          }
        }
        return !fileErrors.value
      }
        const onSubmit = handleSubmit((values) => {
            if (!handleFilesUpload()) return false
            const formData = new FormData()
            formData.append('titulo', modelo.titulo)
            formData.append('fechaPublicacion', modelo.fechaPublicacion)
            formData.append('tipoPublicacionId', modelo.tipoPublicacionId)
            formData.append('file', inputFile.value.files[0])
            updatePublicationesService(modelo.id, formData).then(() => {
                onLoad()
                toast.add({
                    severity: 'success',
                    summary: 'Exito',
                    detail: 'Documento cargado con exito',
                    life: 3000
                })
                isEdit.value = false
                editingRows.value = []
            })
        })

        const onConfirmDelete = (id) => {
            idTemporal.value = id
            displayDeleteRegistro.value = true
        }

        const deletePublication = () => {
            deletePublicationesService(idTemporal.value).then(({ data }) => {
                onLoad()
                displayDeleteRegistro.value = false
                toast.add({ severity: 'success', summary: 'Fila eliminada Correctamente', life: 3000 })
            })
        }

        const onRowReorder = (event) => {
            const rowValue = publications.value[event.dragIndex]
            rowValue.orden = event.dropIndex + 1
            publications.value = event.value
            orderPublicationService(rowValue.id, { orden: rowValue.orden }).then(({ data }) => {
                toast.add({ severity: 'success', summary: 'Filas Reorganizadas Correctamente', life: 3000 })
            })
        }
        const showPublication = async (file) => {
            if (!file) {
                toast.add({ severity: 'warn', summary: 'Documento no encontrado', life: 3000 })
                return false
            }
            const path = await readFileService(file)
            window.open(path, '_blank')
        }

        const formatDate = (date, format) => {
            return dayjs(date).format(format)
        }
        const onChangeTipo = () => {
            modelo.tipoPublicacionId = ''
            addTipo.value = true
        }
        const onFilter = () => {
            const queryParams = route.query
            router.push({ query: { ...queryParams, ...filter.value } })
        }
        const onLoad = () => {
            const queryParams = { ...route.query }
            queryParams.offset = queryParams.offset ? queryParams.offset : 0
            queryParams.limit = queryParams.limit ? queryParams.limit : 10
            publicacionService(queryParams).then(({ data }) => {
                filter.value.total = data.count
                publications.value = data.rows.map(e => {
                    return {
                        ...e,
                        fechaPublicacion: dayjs(e.fechaPublicacion).format('YYYY-MM-DD'),
                        estado: !e.deletedAt
                    }
                })
            })
        }
        const onChangeEstado = (id) => {
            statePublicationesService(id).then(() => {
                onLoad()
                toast.add({ severity: 'success', summary: 'Estado de la fila cambiada Correctamente', life: 3000 })
            })
        }
        const orderFiled = ({ sortField, sortOrder }) => {
            console.log(sortField, sortOrder)
            filter.value.order = `${sortField},${sortOrder}`
            onFilter()
        }
        const fetchQueryParams = () => {
            for (const [key] of Object.entries(filter.value)) {
                const val = route.query[key]
                if (val) {
                    filter.value[key] = key !== 'order' ? parseInt(val) : val
                }
            }
        }
        onMounted(() => {
            fetchQueryParams()
            getTipoPublicaciones()
            onLoad()
        })
        watch(() => route.query, () => {
            onLoad()
        })

      /* Evento para iniciar a editar (el lapiz) */
      const onRowEditInit = () => {
        if (editingRows.value.length > 1) {
          toast.add({
            severity: 'error',
            summary: 'Editar',
            detail: 'Solo puede editar un registro a la vez',
            life: 3000
          })
          editingRows.value = [editingRows.value[0]]
        }
      }

        return {
            filter,
            addTipo,
            onConfirmDelete,
            isDisabledSupplier,
            errors,
            handleReset,
            modelo,
            showPublication,
            filterCategory,
            editingRows,
            editPublication,
            isEdit,
            displayDeleteRegistro,
            publications,
            onRowReorder,
            handleFilesUpload,
            inputFile,
            deletePublication,
            idTemporal,
            filters1,
            selectedProduct,
          fileName,
          fileErrors,
            onSubmit,
            formatDate,
            onLoad,
            onChangeTipo,
            onFilter,
            onChangeEstado,
            orderFiled,
          onRowEditInit
        }
    }

}
</script>

<style scoped>

.title {
    text-align: left;
    font: normal normal bold 27px/33px Roboto;
    letter-spacing: 0px;
    color: #7F7E81;
}
.icon-title {
    color: #6E6E6E 0% 0% no-repeat padding-box;
    margin-right: 10px;
}
.btn-nueva-publicacion {
    text-align: left;
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    background-image: url('../../../../../assets/images/icons/create-publication.png') !important;
    background-repeat: no-repeat;
    background-position-y: center;
    background-origin: content-box;
    text-align: -webkit-center;
    width: 35%;
}
.btn-nueva-publicacion:hover {
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    background-image: url('../../../../../assets/images/icons/create-publication.png') !important;
    /* background-image: url('../../../../../assets/images/icons/create-publication.png') !important; */
    background-repeat: no-repeat !important;
    background-position-y: center !important;
    background-origin: content-box !important;
    text-align: -webkit-center;
    width: 35%;
}

.p-dialog-footer.border-modal-footer {
    border-top: 5px solid #1E40AF !important;
    border-bottom: 5px solid #1E40AF !important;
}
.separator-header {
    margin-top: 15px;
    background: #F4F5FA 0% 0% no-repeat padding-box;
    height: 1px;
    margin-bottom: 15px;
}

</style>
